<template>
  <div class="pl-6">
    <v-row>
      <v-col cols="12">
        <p class="text-body-1 font-weight-bold fix-font">Recording:</p>
        <v-audio
          @playAudio="checkMarkerTrackpoint('play')"
          @pauseAudio="checkMarkerTrackpoint('pause')"
          ref="audioComponenet"
          :file="element_data"
          @setInputTime="setInputTime"
          @setDataMarkingRecording="setDataMarkingRecording"
        >
        </v-audio>
        <p class="red--text mt-2 text-subtitle-1 fix-font">
          * Click the progress bar to get the time you want to select
        </p>
        <!--        <audio id="range" :src="element_data" type="audio/mp3" controls="controls"></audio>-->
        <!--        <a :href="element_data" download="filename.mp3">DownloadButton</a>-->
      </v-col>
      <v-col cols="12">
        <p class="text-body-1 font-weight-bold fix-font" style="">
          Add a new comment:
        </p>
        <div class="ml-10 d-inline-block mb-6" style="width: 100%">
          <v-row>
            <v-col lg="1" md="1" sm="2" class="p-0"
              ><label class="text-body-1 fix-font">Timestamp:</label></v-col
            >
            <v-col cols="9" class="p-0">
              <v-text-field
                disabled
                v-model="timeInput"
                placeholder="Click on the progress bar to select a specific timestamp"
                class="text-body-1 ml-1"
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="1" md="1" sm="2" class="p-0"
              ><label class="text-body-1 fix-font">Comment:</label></v-col
            >
            <v-col cols="9" class="p-0">
              <v-textarea
                solo
                auto-grow
                dense
                rows="1"
                class="text-body-1 ml-1"
                v-model="commentInput"
                @keydown.enter.exact.prevent="getCurrentRecording"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="1" md="1" sm="2" class="p-0"
              ><label class="text-body-1 fix-font">Type:</label></v-col
            >
            <v-col cols="9" class="p-0">
              <v-radio-group
                class="mt-0 pt-1 fix-font"
                row
                v-model="marking_tag_type"
              >
                <v-radio label="Errors" :value="1"></v-radio>
                <v-radio label="Good Uses" :value="2"></v-radio>
              </v-radio-group>
              <p class="text-body-1 fix-font p-0 mb-0">Select a tag:</p>
              <v-autocomplete
                :items="marking_tag_by_type"
                item-text="name"
                item-value="id"
                v-model="marking_tag_ids"
                chips
                deletable-chips
                dense
                multiple
                small-chips
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="1" md="1" sm="2" class="p-0"></v-col>
            <v-col cols="9" class="p-0">
              <v-btn outlined color="blue" class="mb-5" @click="addDataMarking">
                <v-icon small>mdi-plus</v-icon>
                <span class="font-weight-bold">Add</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="ml-10">
          <v-row>
            <v-col cols="12" class="table-responsive pt-0">
              <table
                class="table datatable-bordered table-vertical-center table-head-bg"
              >
                <thead class="thead-ipp">
                  <tr>
                    <th class="text-button" scope="col">Time</th>
                    <th class="text-button" scope="col">Comment</th>
                    <th class="text-button" scope="col">Tags</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data, i) in dataMarking">
                    <tr :key="i">
                      <td @click="setTimePlay(data.time)" class="text-time">
                        {{ data.time }}
                      </td>
                      <td style="word-break: break-word" class="text-justify">
                        {{ data.comment }}
                      </td>
                      <td>
                        <template
                          v-if="
                            data.marking_tag_ids !== undefined &&
                            data.marking_tag_ids.length > 0
                          "
                        >
                          <template
                            v-for="(tag, index) in transformMarkingTag(
                              data.marking_tag_ids,
                              data.marking_tag_type
                            )"
                          >
                            <template v-if="tag">
                              <v-btn
                                class="ml-1"
                                :color="
                                  data.marking_tag_type === 2
                                    ? 'success'
                                    : 'error'
                                "
                                :key="index"
                                >{{ tag.name }}</v-btn
                              >
                            </template>
                          </template>
                        </template>
                      </td>
                      <td style="display: flex">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="#1c5580"
                              @click="editData(i)"
                              >mdi-pencil</v-icon
                            >
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              class="ml-2"
                              v-on="on"
                              color="red"
                              @click="deleteData(i)"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!dataMarking.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogEditData" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Edit</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogEditData = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div style="width: 100%" class="d-inline-block">
                <v-row>
                  <v-col cols="2" class="text-right"
                    ><label class="text-h6 mb-0 font-weight-bold text-right"
                      >Time</label
                    ></v-col
                  >
                  <v-col cols="9">
                    <v-text-field dense v-model="edit_time" solo></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="2" class="text-right pt-0"
                    ><label class="text-h6 mb-0 font-weight-bold text-right"
                      >Comment</label
                    ></v-col
                  >
                  <v-col cols="9" class="pt-0">
                    <v-textarea
                      solo
                      auto-grow
                      dense
                      rows="1"
                      class="text-body-1"
                      v-model="edit_comment"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="2" class="text-right pt-0"
                    ><label class="text-h6 mb-0 font-weight-bold text-right"
                      >Tag</label
                    ></v-col
                  >
                  <v-col cols="9" class="pt-0">
                    <v-radio-group
                      class="mt-0 pt-0"
                      row
                      v-model="marking_tag_type"
                      @change="edit_marking_tag_ids = []"
                    >
                      <v-radio label="error" :value="1"></v-radio>
                      <v-radio label="good" :value="2"></v-radio>
                    </v-radio-group>
                    <v-autocomplete
                      :items="marking_tag_by_type"
                      item-text="name"
                      item-value="id"
                      v-model="edit_marking_tag_ids"
                      chips
                      deletable-chips
                      dense
                      multiple
                      small-chips
                      solo
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              class="font-weight-bold"
              outlined
              text
              @click="updateData"
              >Update</v-btn
            >
            <v-btn
              color="darken-1"
              style="color: #f64e60"
              text
              @click="dialogEditData = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import marking_trackpoint from "./mixins/marking-trackpoint";
export default {
  name: "MarkingForRecording",
  components: {
    // VAudio: () => import('./Audio'),
    VAudio: () => import("./tools/AudioMarking"),
  },
  mixins: [marking_trackpoint],
  data() {
    return {
      element_data: null,
      sound: {},
      timeInput: null,
      commentInput: "",
      dataMarking: [],
      dialogEditData: false,
      edit_time: "",
      edit_comment: "",
      indexData: null,
      marking_tag_ids: [],
      marking_tag_type: null,
      edit_marking_tag_ids: [],
      marking_tag_by_type: [],
    };
  },
  props: {
    file: {
      type: String,
      default: null,
    },
    dataRecording: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    ...mapState({
      data_marking_tag_speaking: (state) =>
        state.markingTicketAnswerStore.data_marking_tag_speaking,
    }),
  },
  watch: {
    dataMarking: {
      handler: function (val) {
        this.$emit("getMarkingData", val);
      },
      deep: true,
    },
    marking_tag_type() {
      this.marking_tag_ids = [];
      this.setMarkingTagByType();
    },
  },
  created() {
    this.setElementData();
    this.setDataMarking();
  },
  mounted() {
    this.setMarkingTagByType();
  },
  methods: {
    setElementData() {
      if (this.file != null) {
        this.element_data = this.file;
      }
    },
    setDataMarking() {
      if (this.dataRecording != null) {
        this.dataMarking = this.dataRecording;
      }
    },
    onElementChange(file) {
      let vm = this;
      if (file == undefined) {
        vm.element_data = "";
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        vm.element_data = e.target.result;
      };
    },
    addDataMarking() {
      if (this.timeInput == null) {
        this.$toasted.error(" Time is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
        });
        return;
      }
      if (this.commentInput == "") {
        this.$toasted.error(" Comment is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
        });
        return;
      }
      let data = {
        time: this.timeInput,
        comment: this.commentInput,
        marking_tag_ids: this.marking_tag_ids,
        marking_tag_type: this.marking_tag_type,
      };
      this.dataMarking.push(data);
      this.checkMarkerTrackpoint("annotate");
      this.setDefaultInput();
    },
    setDefaultInput() {
      this.timeInput = null;
      this.commentInput = "";
      this.marking_tag_ids = [];
      this.marking_tag_type = null;
      this.edit_marking_tag_ids = [];
    },
    setTimePlay(time) {
      this.$refs.audioComponenet.setPlayTime(time);
      const cmtAudio = this.dataMarking.find(e => e.time == time);
      this.commentInput = cmtAudio && cmtAudio.comment;
      this.timeInput = time;
      this.marking_tag_ids = cmtAudio?.marking_tag_ids;
      this.marking_tag_type = cmtAudio?.marking_tag_type;
    },
    setCurrentTime(time) {
      this.$refs.audioComponenet.setCurrentTime(time);
      const cmtAudio = this.dataMarking.find(e => e.time == time);
      this.commentInput = cmtAudio && cmtAudio.comment;
      this.timeInput = time;
      setTimeout(()=>{
        this.marking_tag_ids = cmtAudio?.marking_tag_ids;
      },100);
      this.marking_tag_type = cmtAudio?.marking_tag_type;
    },
    setInputTime(time) {
      this.timeInput = time;
      const cmtAudio = this.dataMarking.find(e => e.time == time);
      this.commentInput = cmtAudio && cmtAudio.comment;
      this.marking_tag_ids = cmtAudio?.marking_tag_ids;
      this.marking_tag_type = cmtAudio?.marking_tag_type;
    },
    deleteData(i) {
      this.dataMarking.splice(i, 1);
    },
    editData(i) {
      this.dialogEditData = true;
      this.edit_time = this.dataMarking[i].time;
      this.edit_comment = this.dataMarking[i].comment;
      this.indexData = i;
      this.edit_marking_tag_ids =
        this.dataMarking[i].marking_tag_ids === undefined ||
        this.dataMarking[i].marking_tag_ids.length <= 0
          ? []
          : this.dataMarking[i].marking_tag_ids;
      this.marking_tag_type =
        this.dataMarking[i].marking_tag_type === undefined
          ? null
          : this.dataMarking[i].marking_tag_type;
    },
    updateData() {
      this.dataMarking[this.indexData].time = this.edit_time;
      this.dataMarking[this.indexData].comment = this.edit_comment;
      this.dataMarking[this.indexData].marking_tag_ids =
        this.edit_marking_tag_ids;
      this.dataMarking[this.indexData].marking_tag_type = this.marking_tag_type;
      this.setDefaultInput();
      this.dialogEditData = false;

      this.commentInput = this.edit_comment;
      this.timeInput = this.edit_time;
      this.marking_tag_ids = this.edit_marking_tag_ids;
      this.marking_tag_type = this.marking_tag_type;
      this.setTimePlay(this.edit_time);
    },
    getCurrentRecording() {
      this.$refs.audioComponenet.getCurrentTimeAudio();
    },
    setDataMarkingRecording(time) {
      this.timeInput = time;
      this.addDataMarking();
    },
    setMarkingTagByType() {
      let type = this.marking_tag_type;
      let data_marking_tag = JSON.parse(
        JSON.stringify(this.data_marking_tag_speaking)
      );
      if (type === 1) {
        this.marking_tag_by_type = data_marking_tag.error;
      } else if (type === 2) {
        this.marking_tag_by_type = data_marking_tag.good_use;
      } else {
        this.marking_tag_by_type = [];
      }
    },
    transformMarkingTag(marking_tag_ids = [], type = null) {
      let data_marking_tag_speaking = JSON.parse(
        JSON.stringify(this.data_marking_tag_speaking)
      );
      let check_data_tag = [];
      if (type === 1) {
        check_data_tag = data_marking_tag_speaking.error;
      } else if (type === 2) {
        check_data_tag = data_marking_tag_speaking.good_use;
      } else {
        check_data_tag = [];
      }
      return marking_tag_ids.map((id) => {
        return check_data_tag.filter((e) => e.id === id)[0];
      });
    },
  },
};
</script>

<style lang="css" scoped>
.text-body-1 {
  font-size: 1.1rem !important;
}
.fix-font {
  font-family: "Montserrat", sans-serif !important;
}
.act-sound {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.text-time {
  color: #43a047;
  cursor: pointer;
}
</style>
